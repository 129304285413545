import React, { useEffect, useState } from 'react';

import { Container, Segment } from 'semantic-ui-react';

const Footer = () => {
  const [Footer, setFooter] = useState('');
  useEffect(() => {
    let savedFooter = localStorage.getItem('footer_html');
    if (!savedFooter) savedFooter = '';
    setFooter(savedFooter);
  });

  return (
    <Segment vertical>
      <Container textAlign="center">
        {Footer === '' ? (
          <div></div>
        ) : (
          <div
            className="custom-footer"
            dangerouslySetInnerHTML={{ __html: Footer }}
          ></div>
        )}
      </Container>
    </Segment>
  );
};

export default Footer;
