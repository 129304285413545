import React, { useEffect, useState } from 'react';
import { Button, Form, Label, Modal, Pagination, Table, Popup, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { API, copy, showError, showSuccess, showWarning, timestamp2string } from '../helpers';

import { ITEMS_PER_PAGE } from '../constants';

function renderTimestamp(timestamp) {
  return (
    <>
      {timestamp2string(timestamp)}
    </>
  );
}

function renderStatus(status) {
  switch (status) {
    case 1:
      return <Label basic color='green'>已启用</Label>;
    case 2:
      return <Label basic color='red'> 已禁用 </Label>;
    case 3:
      return <Label basic color='yellow'> 已过期 </Label>;
    case 4:
      return <Label basic color='grey'> 已耗尽 </Label>;
    default:
      return <Label basic color='black'> 未知状态 </Label>;
  }
}

const TokensTable = () => {
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searching, setSearching] = useState(false);
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [targetTokenIdx, setTargetTokenIdx] = useState(0);
  const [redemptionCode, setRedemptionCode] = useState('');
  const [topUpLink, setTopUpLink] = useState('');
  const [onTopUp, setOnTopUp] = useState(false);

  const loadTokens = async (startIdx) => {
    const res = await API.get(`/api/token/?p=${startIdx}`);
    const { success, message, data } = res.data;
    if (success) {
      if (startIdx === 0) {
        setTokens(data);
      } else {
        let newTokens = tokens;
        newTokens.push(...data);
        setTokens(newTokens);
      }
    } else {
      showError(message);
    }
    setLoading(false);
  };

  const onPaginationChange = (e, { activePage }) => {
    (async () => {
      if (activePage === Math.ceil(tokens.length / ITEMS_PER_PAGE) + 1) {
        // In this case we have to load more data and then append them.
        await loadTokens(activePage - 1);
      }
      setActivePage(activePage);
    })();
  };

  useEffect(() => {
    loadTokens(0)
      .then()
      .catch((reason) => {
        showError(reason);
      });
    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      if (status.top_up_link) {
        setTopUpLink(status.top_up_link);
      }
    }
  }, []);

  const manageToken = async (id, action, idx) => {
    let data = { id };
    let res;
    switch (action) {
      case 'delete':
        res = await API.delete(`/api/token/${id}/`);
        break;
      case 'enable':
        data.status = 1;
        res = await API.put('/api/token/?status_only=true', data);
        break;
      case 'disable':
        data.status = 2;
        res = await API.put('/api/token/?status_only=true', data);
        break;
    }
    const { success, message } = res.data;
    if (success) {
      showSuccess('操作成功完成！');
      let token = res.data.data;
      let newTokens = [...tokens];
      let realIdx = (activePage - 1) * ITEMS_PER_PAGE + idx;
      if (action === 'delete') {
        newTokens[realIdx].deleted = true;
      } else {
        newTokens[realIdx].status = token.status;
      }
      setTokens(newTokens);
    } else {
      showError(message);
    }
  };

  const searchTokens = async () => {
    if (searchKeyword === '') {
      // if keyword is blank, load files instead.
      await loadTokens(0);
      setActivePage(1);
      return;
    }
    setSearching(true);
    const res = await API.get(`/api/token/search?keyword=${searchKeyword}`);
    const { success, message, data } = res.data;
    if (success) {
      setTokens(data);
      setActivePage(1);
    } else {
      showError(message);
    }
    setSearching(false);
  };

  const handleKeywordChange = async (e, { value }) => {
    setSearchKeyword(value.trim());
  };

  const sortToken = (key) => {
    if (tokens.length === 0) return;
    setLoading(true);
    let sortedTokens = [...tokens];
    sortedTokens.sort((a, b) => {
      return ('' + a[key]).localeCompare(b[key]);
    });
    if (sortedTokens[0].id === tokens[0].id) {
      sortedTokens.reverse();
    }
    setTokens(sortedTokens);
    setLoading(false);
  };

  const topUp = async () => {
    if (redemptionCode === '' || onTopUp) {
      return;
    }
    setOnTopUp(true)
    const res = await API.post('/api/token/topup/', {
      id: tokens[targetTokenIdx].id,
      key: redemptionCode
    });
    const { success, message, data } = res.data;
    if (success) {
      showSuccess('充值成功！');
      let newTokens = [...tokens];
      let realIdx = (activePage - 1) * ITEMS_PER_PAGE + targetTokenIdx;
      newTokens[realIdx].remain_quota += data;
      setTokens(newTokens);
      setRedemptionCode('');
      setShowTopUpModal(false);
    } else {
      showError(message);
    }
    setOnTopUp(false)
  }

  return (
    <>
      <Form onSubmit={searchTokens}>
        <Form.Input
          icon='search'
          fluid
          iconPosition='left'
          placeholder='搜索令牌的 ID 和名称 ...'
          value={searchKeyword}
          loading={searching}
          onChange={handleKeywordChange}
        />
      </Form>

      <Table basic>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              style={{ cursor: 'pointer' }}
              onClick={() => {
                sortToken('id');
              }}
            >
              ID
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ cursor: 'pointer' }}
              onClick={() => {
                sortToken('name');
              }}
            >
              名称
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ cursor: 'pointer' }}
              onClick={() => {
                sortToken('status');
              }}
            >
              状态
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ cursor: 'pointer' }}
              onClick={() => {
                sortToken('remain_quota');
              }}
            >
              额度
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ cursor: 'pointer' }}
              onClick={() => {
                sortToken('created_time');
              }}
            >
              创建时间
            </Table.HeaderCell>
            <Table.HeaderCell
              style={{ cursor: 'pointer' }}
              onClick={() => {
                sortToken('expired_time');
              }}
            >
              过期时间
            </Table.HeaderCell>
            <Table.HeaderCell>操作</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tokens
            .slice(
              (activePage - 1) * ITEMS_PER_PAGE,
              activePage * ITEMS_PER_PAGE
            )
            .map((token, idx) => {
              if (token.deleted) return <></>;
              return (
                <Table.Row key={token.id}>
                  <Table.Cell>{token.id}</Table.Cell>
                  <Table.Cell>{token.name ? token.name : '无'}</Table.Cell>
                  <Table.Cell>{renderStatus(token.status)}</Table.Cell>
                  <Table.Cell>{token.unlimited_quota ? '无限制' : token.remain_quota + ' 点'}</Table.Cell>
                  <Table.Cell>{renderTimestamp(token.created_time)}</Table.Cell>
                  <Table.Cell>{token.expired_time === -1 ? '永不过期' : renderTimestamp(token.expired_time)}</Table.Cell>
                  <Table.Cell>
                    <div>
                    <Popup 
                      trigger={
                        <Button
                          size={'small'}
                          positive
                          onClick={async () => {
                            if (await copy(token.key)) {
                              showSuccess('已复制到剪贴板！');
                            } else {
                              showWarning('无法复制到剪贴板，请手动复制，已将令牌填入搜索框。');
                              setSearchKeyword(token.key);
                            }
                          }}
                        >
                          复制 API 令牌
                        </Button>
                      }>
                        <Popup.Content>
                        Online GPT 令牌是您使用我们服务需要的认证信息，您可以在网页版使用或者阅读开发文档来接入我们的服务
                        </Popup.Content>
                      </Popup>
                      <Popup 
                        trigger={
                        <Button
                          size={'small'}
                          onClick={() => {
                            manageToken(
                              token.id,
                              token.status === 1 ? 'disable' : 'enable',
                              idx
                            );
                          }}
                        >
                          {token.status === 1 ? '禁用' : '启用'}
                        </Button>
                        }>
                        <Popup.Content>
                        启用或者禁用令牌
                        </Popup.Content>
                      </Popup>
                      <Popup 
                        trigger={
                          <Button
                            size={'small'}
                            as={Link}
                            to={'/token/edit/' + token.id}
                          >
                            编辑
                          </Button>
                        }>
                        <Popup.Header>如果令牌不可用</Popup.Header>
                        <Popup.Content>
                        <List as="ol">
                          <List.Item as='li'>使用启用按钮启用本令牌</List.Item>
                          <List.Item as='li'>编辑本令牌的过期时间</List.Item>
                        </List>
                        </Popup.Content>
                      </Popup>
                    </div>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='8'>
              <Button size='small' as={Link} to='/token/add' loading={loading}>
                添加新的令牌
              </Button>
              <Pagination
                floated='right'
                activePage={activePage}
                onPageChange={onPaginationChange}
                size='small'
                siblingRange={1}
                totalPages={
                  Math.ceil(tokens.length / ITEMS_PER_PAGE) +
                  (tokens.length % ITEMS_PER_PAGE === 0 ? 1 : 0)
                }
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>

      <Modal
        onClose={() => setShowTopUpModal(false)}
        onOpen={() => setShowTopUpModal(true)}
        open={showTopUpModal}
        size={'mini'}
      >
        <Modal.Header>通过兑换码为令牌「{tokens[targetTokenIdx]?.name}」充值</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {/*<Image src={status.wechat_qrcode} fluid />*/}
            {
              topUpLink && <p>
                  <a target='_blank' href={topUpLink}>点击此处获取兑换码</a>
              </p>
            }
            <Form size='large'>
              <Form.Input
                fluid
                placeholder='兑换码'
                name='redemptionCode'
                value={redemptionCode}
                onChange={(e) => {
                  setRedemptionCode(e.target.value);
                }}
              />
              <Button color='' disabled={onTopUp} fluid size='large' onClick={topUp}>
                充值
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default TokensTable;
